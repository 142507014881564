<template>
  <div
    data-cy-region="payment-summary"
    class="PaymentSummary rounded-5xs"
    :class="{
      'bg-white text-primary-A900': inverted,
      'text-primary-A900 ': !inverted,
    }"
  >
    <h2 class="mt-2 mx-auto text-lg-1 px-8 font-bold text-primary-A900">
      {{ $t('summary') }}
    </h2>

    <p class="mt-6 flex items-center text-sm px-8">
      <span class="text-base text-secondary-700">{{ $t('subtotal') }}</span>
      <Money class="ml-auto text-base" :value="subtotal" />
    </p>

    <slot name="extensions" />

    <p v-if="shippingFees || shippingFees === 0" class="mt-2 flex items-center text-base px-8">
      <span class="text-base text-secondary-700">{{ $t('shippingFees') }}</span>
      <Money class="ml-auto text-base" :value="shippingFees" fallback="to be calculated" />
    </p>

    <template v-if="discounts && discounts.length">
      <p v-for="({ label, value }, index) in discounts" :key="index" class="mt-2 flex items-center text-base px-8">
        <span class="text-base text-secondary-700">{{ label }}</span>
        <Money class="ml-auto text-base" :value="value * -1" fallback="to be calculated" />
      </p>
    </template>

    <div class="md:mx-5 mt-3 bg-green-100 h-unit-2 md:h-unit"></div>

    <div class="mt-3 flex items-center text-base px-8">
      <i18n-t class="text-base text-secondary-700" tag="span" keypath="paymentSummary">
        <template #total>
          <span class="text-primary-A900 text-base">
            {{ $t('total') }}
          </span>
        </template>
      </i18n-t>

      <Money class="ml-auto text-primary-700 text-lg-1 font-semibold font-display" :value="total" />
    </div>

    <div class="px-8 lg:px-0">
      <slot name="cta-placeholder" />
    </div>
  </div>
</template>

<script setup lang="ts">
defineComponent({
  name: 'PaymentSummary',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  total: {
    type: Number,
    default: 0,
  },
  subtotal: {
    type: Number,
    default: 0,
  },
  shippingFees: {
    type: Number,
    default: () => null,
  },
  hasInvalidItem: {
    type: Boolean,
    default: false,
  },
  discounts: {
    type: Array as PropType<Array<{ label: string; value: number }>>,
    default: () => [],
  },
  inverted: {
    type: Boolean,
    default: false,
  },
});

const { t: $t } = useI18n({ useScope: 'local' });
</script>

<style lang="postcss" scoped>
.PaymentSummary {
  @apply md:self-start py-8;
  filter: drop-shadow(0px 0px 48px rgba(21, 44, 115, 0.15));
  top: 30px;

  .disable-checkout {
    @apply opacity-50;
    pointer-events: none;
  }
}
</style>

<i18n>
{
  "en": {
    "summary": "Payment Summary",
    "subtotal": "Subtotal",
    "paymentSummary": "{total} + VAT included",
    "shippingFees": "Shipping Fees",
    "taxesIncluded": "All taxes are included",
    "proceed": "Proceed to Checkout",
    "reviewPayment": "Review Payment",
    "total": "Total"
  },
  "ar": {
    "summary": "تفاصيل الطلب",
    "subtotal": "الاجمالي الفرعي",
    "paymentSummary": "{total} + ضريبه القيمه المضافه", 
    "shippingFees": "مصاريف الشحن",
    "taxesIncluded": "يشمل قيمه الضرائب",
    "proceed": "المتابعه للدفع",
    "reviewPayment": "مراجعه الدفع",
    "total": "الاجمالي",
  }
}
</i18n>
